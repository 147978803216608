var variables = require(process.env.themeFolder);

requirejs([
        'jquery',
        'swiper'
    ],
    function ($, Swiper) {
        $(document).ready(function () {
            $('.campout-shop-reviews .block-static-block').each(function (index, slider) {
                $(this).addClass('swiper-container');
                $(this).find('.pd-row:first-child').addClass('swiper-wrapper');
                $(this).find('.swiper-wrapper .pd-col').addClass('swiper-slide');
                $(this).append("<div class=\"swiper-pagination\"></div>");
                $(this).append("<div class=\"swiper-button-next\"></div>");
                $(this).append("<div class=\"swiper-button-prev\"></div>");

                var galleryTopSettings = {
                    loop: 0,
                    initialSlide: 0,
                    slidesPerView: 1,
                    spaceBetween: 25,
                    clickable: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    breakpoints: {
                        420: {
                            slidesPerView: 1,
                            spaceBetween: 25,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 25,
                        },
                        992: {
                            slidesPerView: 3,
                            spaceBetween: 25,
                        },
                    }
                };

                var galleryTop = new Swiper(slider, galleryTopSettings);
            });

            $('body.catalog-product-view .campout-shop-reviews').each(function (index, slider) {
                $(this).addClass('swiper-container');
                $(this).find('.pd-row:first-child').addClass('swiper-wrapper');
                $(this).find('.swiper-wrapper .pd-col').addClass('swiper-slide');
                $(this).append("<div class=\"swiper-pagination\"></div>");
                $(this).append("<div class=\"swiper-button-next\"></div>");
                $(this).append("<div class=\"swiper-button-prev\"></div>");

                var galleryTopSettings = {
                    loop: 0,
                    initialSlide: 0,
                    slidesPerView: 1,
                    spaceBetween: 30,
                    clickable: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                };

                var galleryTop = new Swiper(slider, galleryTopSettings);
            });
        });
    });
