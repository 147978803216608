<template>
    <div class="redirect-component">
        <div v-on:click="startRedirect" v-bind:title="buttonText" class="action primary redirect-button" id="product-redirect-button"><span v-text="buttonText"></span></div>

        <div class="redirect-popup-screen" :class="popupActive ? 'active' : ''">
            <div class="redirect-popup-screen-inner">
                <img v-show="popupImage" v-bind:src="popupImage" alt="">
                <div class="redirect-popup-text">
                    <div v-text="popupText"></div>
                    <div class="redirect-timer" v-text="redirectTimeout"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            buttonText: this.$parent.buttonText,
            url: this.$parent.redirectUrl,
            popupText: this.$parent.popupText,
            popupImage: this.$parent.popupImage,
            redirectTimeout: this.$parent.popupRedirectTime,
            popupActive: false
        }
    },
    methods: {
        startRedirect() {
            let self = this;

            if (!this.url) {
                return;
            }

            this.popupActive = true;

            this.setDatalayerClick();

            let redirectInterval = setInterval(function() {
                self.redirectTimeout -= 1;

                if (self.redirectTimeout <= 0) {
                   clearInterval(redirectInterval);
                   window.location.href = self.url;
                }
            }, 1000);
        },

        setDatalayerClick() {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
                'event': 'co_redirect_product_external',
            });
        }
    }
}
</script>
