<template>
    <div v-if="showPopup" class="vue-popup location-check-popup">
        <div class="modal-backdrop"></div>
        <div class="modal" v-on-clickaway="cancel">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <strong class="modal-title" v-html="t.goTo" />
                        <div class="close">
                            <i @click="cancel()" class="fas fa-times"></i>
                        </div>
                    </div>
                    <div class="modal-body">
                         <p class="content" v-text="t.visitingFrom" />
                    </div>
                    <div class="modal-footer">
                        <button @click="cancel()" title="Blijf hier" class="cancel">
                            <span v-text="t.stayHere" />
                        </button>
                        <button @click="redirect()" title="Verwijs mij door" class="action primary">
                            <span v-text="t.redirectMe" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import VueCookies from 'vue-cookies'
    import { mixin as clickaway } from 'vue-clickaway'

    export default {
        mixins: [ clickaway ],
        data() {
            return {
                showPopup: '',
                currentLocation: '',
                locationCheckData: window.locationCheckData,
                t: locationCheckData.translatableStrings
            }
        },
        methods: {
            cancel() {
                this.showPopup = false
                $cookies.set('showLocationPopup', 0, '1d')
                this.checkShowPopup()
            },
            redirect() {
                this.showPopup = false
                $cookies.set('showLocationPopup', 0, '1d')
                window.location.replace(locationCheckData.redirectStoreUrl)
            },
            getCurrentLocation() {
                return axios.get('https://ipinfo.io/country')
                    .then(response => {
                        this.currentLocation = response.data.replace(/(\r\n|\n|\r)/gm, '')
                    })
            },
            clientLocationIsStoreLocation() {
                return this.locationCheckData.storeLocale.includes(this.currentLocation)
            },
            async checkShowPopup() {
                if ($cookies.get('showLocationPopup') == false) {
                    return false
                }
                await this.getCurrentLocation()
                if (this.clientLocationIsStoreLocation()) {
                    this.showPopup = false
                } else {
                    this.showPopup = true
                }
            }
        },
        mounted: async function() {
            this.checkShowPopup()
        }
    }
</script>
