import Vue from 'vue'
import QuickFilter from './quick-filter/QuickFilter.vue'

let elements = document.getElementsByClassName('quick-filter');
if (elements.length) {
    for (let i = 0; i < elements.length; i++) {
        let elementId = elements[i].id;
        (function (el) {
            new Vue({
                el: '#' + elementId,
                render: h => h(QuickFilter),
                data: () => Object.assign({}, el.dataset)
            })
        })(document.getElementById(elementId));
    }
}
