var variables = require(process.env.themeFolder);

requirejs([
    'jquery', 'mage/translate'
],
function($, $t) {
    $('.show-more-less').click(function() {
        /** Toggle class 'expanded' for element with button's 'data-toggle' value class */
        $('.' + $(this).data('toggle')).toggleClass('expanded');

        let showMoreText = $t('Show more');
        let showLessText = $t('Show less');

        if ($(this).data('show-more-text') && $(this).data('show-less-text')) {
            showMoreText = $(this).data('show-more-text');
            showLessText = $(this).data('show-less-text');
        }

        /** Toggle 'expanded' class for button element and change text */
        if ($(this).toggleClass('expanded').hasClass('expanded')) {
            $(this).find('span').text(showLessText);
        } else {
            $(this).find('span').text(showMoreText);
            let elementTop = $('.' + $(this).data('toggle')).first().offset().top;

            /** Scroll to top of list element if position is below element top */
            if ($('html, body').scrollTop() > elementTop) {
                $('html, body').scrollTop(elementTop);
            }
        }
    });

    let isMobile = $(window).width() <= variables.md;
    /** Show additional-attributes more/less button */
    if ((!isMobile && $('.additional-attributes tr').length > 10) ||
        isMobile && $('.additional-attributes tr').length > 5) {
        $('.additional-attributes-wrapper .actions-toolbar').show();
    }
});
