exports = module.exports = require("../../../../../../../../vendor/justbetter/magento2mix/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "", ""]);

// exports
exports.locals = {
	"gridGapColumn": "15px",
	"gridGapRow": "30px",
	"xs": "420px",
	"sm": "768px",
	"md": "992px",
	"lg": "1200px",
	"xl": "1600px",
	"hiddenMobileMenu": "1200px",
	"mainSlidesXs": "1",
	"mainSlidesSm": "2",
	"mainSlidesMd": "3",
	"mainSlidesLg": "3",
	"mainSlidesXl": "4",
	"mainSlidesSpaceBetweenSmall": "15px",
	"mainSlidesSpaceBetween": "15px",
	"mainSlideLoop": "0",
	"upsellSlidesXs": "1",
	"upsellSlidesSm": "2",
	"upsellSlidesMd": "3",
	"upsellSlidesLg": "3",
	"upsellSlidesXl": "4",
	"upsellSlidesSpaceBetween": "15px",
	"upsellLoop": "0",
	"crossellSlidesXs": "1",
	"crossellSlidesSm": "2",
	"crossellSlidesMd": "3",
	"crossellSlidesLg": "3",
	"crossellSlidesXl": "4",
	"crossellSlidesSpaceBetween": "15px",
	"crossellLoop": "0",
	"relatedSlidesXs": "1",
	"relatedSlidesSm": "2",
	"relatedSlidesMd": "3",
	"relatedSlidesLg": "3",
	"relatedSlidesXl": "4",
	"relatedSlidesSpaceBetween": "15px",
	"relatedLoop": "0",
	"galleryThumbsUseControl": "false",
	"galleryThumbsNavButtons": "true",
	"galleryThumbsFreeMode": "false",
	"galleryThumbsCenteredSlides": "false",
	"galleryThumbsDirection": "horizontal",
	"galleryThumbsSpaceBetween": "15",
	"galleryThumbsSlidesPerView": "7",
	"galleryThumbsLoop": "0",
	"galleryTopLoop": "0"
};