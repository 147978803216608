import Vue from 'vue'
import ProductRedirectComponent from './product-redirect-component/ProductRedirectComponent.vue'

let el = document.getElementById('product-redirect-component');

if (el) {
    new Vue({
        el: '#product-redirect-component',
        render: h => h(ProductRedirectComponent),
        data: () => Object.assign({}, el.dataset)
    })
}
