<template>
    <form class="quick-filter">
        <div class="title">
            <span v-text="title" />
        </div>
        <div class="content">
            <quick-filter-select
                v-for="(filterOption, index) in filterOptions"
                :key="filterOption.attribute_code"
                v-model="selectedOptions[filterOption.attribute_code]"
                :index="filterOption.attribute_code"
                :label="filterOption.frontend_label"
                :inputName="filterOption.attribute_code"
                :wrapperId="filterOption.attribute_code + '_select_container'"
                :options="options[filterOption.attribute_code]"
                :change="selectAttribute"
                :disabled="!options[filterOption.attribute_code].length" />
            <div>
                <a :disabled="!url" :href="url" v-text="buttonText" />
            </div>
        </div>
    </form>
</template>

<script>
import axios from 'axios'
import Select from './components/Select.vue'

export default {
    components: {
        'quick-filter-select': Select
    },
    data() {
        return {
            title: this.$parent.title,
            filterOptions: JSON.parse(this.$parent.filterOptions),
            buttonText: this.$parent.buttonText,
            options: {'brand': [], 'type': [], 'year': []},
            selectedOptions: {'brand': '', 'type': '', 'year': ''},
            url: ''
        }
    },
    methods: {
        selectAttribute(attribute_code) {
            let attributeOptions = this.selectedOptions[attribute_code] ? this.selectedOptions[attribute_code] : false
            if (attributeOptions) {
                let selectedOptions = []
                for (const [code, value] of Object.entries(this.selectedOptions)) {
                    selectedOptions.push(
                        {
                            code: code,
                            value: value
                        }
                    )
                }
                this.getRemainingOptions(selectedOptions)
            }
        },

        getRemainingOptions(attributes) {
            let queryParams = ''
            if (attributes) {
                attributes.forEach((attribute, index) => {
                    if (index === 0) {
                        queryParams = queryParams + '?' + attribute.code + '='
                    }

                    if (attribute.value !== '') {
                        queryParams = queryParams + attribute.value
                        let nextAttribute = this.filterOptions[index + 1] !== undefined ? this.filterOptions[index + 1] : false;
                        if (nextAttribute) {
                            queryParams = queryParams + '&' + nextAttribute.attribute_code + '='
                        }
                    }
                })

                axios.get('/quickfilter/quickfilter/index' + queryParams)
                    .then(response => {
                        if (response.data.attribute) {
                            this.options[response.data.attribute] = response.data.values;
                        }

                        let searchParams = new URLSearchParams(queryParams);
                        if (response.data.url) {
                            this.url = new URL(response.data.url)
                            searchParams.forEach((value, key) => {
                                this.url.searchParams.append(key, value)
                            })
                        }
                    })
            }
        }
    },
    created() {
        this.getRemainingOptions([
            {
                code: this.filterOptions[0].attribute_code,
                value: ''
            }
        ])
    }
}
</script>
