<template>
    <div class="form-group">
        <div :id="wrapperId">
            <select
                class="form-control"
                :value="value"
                @input="$emit('input', $event.target.value)"
                :name="inputName"
                :disabled="disabled"
                @change="change(index)">
                <option
                    value=""
                    v-text="label" />
                <option
                    v-for="option in options"
                    :value="option.value"
                    v-text="option.label" />
            </select>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            index: {
                type: String,
                required: true
            },
            label: {
                type: String,
                default: 'Select'
            },
            inputName: {
                type: String,
                required: true
            },
            wrapperId: {
                type: String,
                required: false
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            },
            options: {
                required: false
            },
            value: {
                required: false
            },
            change: {
                required: true
            }
        }
    }
</script>
