requirejs([
    'jquery'
], function (jQuery) {
    jQuery(window).on('load', function () {
        checkForConfiguratorScroll();
    });

    jQuery('.search-link').on('click', function () {
        checkForConfiguratorScroll();
    });

    function checkForConfiguratorScroll() {
        if (window.location.href.indexOf("#configurator") > -1) {

            let el = jQuery('.campout-quick-filter');
            let elOffset = el.offset().top;
            let elHeight = el.height();
            let windowHeight = jQuery(window).height();
            let offset = 0;


            if (elHeight < windowHeight) {
                offset = elOffset - ((windowHeight / 2) - (elHeight / 2));
            } else {
                offset = elOffset;
            }
            let speed = 750;
            jQuery('html, body').animate({scrollTop: offset}, speed);

            jQuery('.campout-quick-filter .quick-filter').removeClass('active');

            let interval = setInterval(function () {
                jQuery('.campout-quick-filter .quick-filter').toggleClass('active');
            }, 1000);

            setTimeout(function () {
                clearInterval(interval);
                jQuery('.campout-quick-filter .quick-filter').removeClass('active');
            }, 5000);
        }
    }
});
